.MuiListItemIcon-root {
    min-width: 10px !important;
}

.btn-icon-menu {
    color: rgb(248, 24, 24);
}

.text-menu {
    color: rgb(71, 71, 71);
    font-weight: bolder !important;
}

.text-submenu {
    color: rgb(71, 71, 71);
    font-weight: normal !important;
}

.titulo {
    color: rgb(107, 118, 128);
}

.imagenModal {
    margin:20px auto;
    border-radius:5px;
    border: 1px solid #999;
    padding: 10px;
    max-width: 600px;
    max-height: 90vh;
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-size: cover;
}

.container-infoventas{
    min-width: 100%;
    max-width: 100%;
    align-content: center;
}

@media screen and (min-width:838px) {
    .infoventas{
        min-width: 70%;
        max-width: 70%;
        margin-left: auto;
        margin-right: auto
    }
}

.thumbs {
    display: none !important;
}

@media screen and (max-width:838px) {
    .titulo{
        font-size: smaller;
    }
    .imagenModal {
        margin:20px auto;
        border-radius:5px;
        border: 1px solid #999;
        padding:10px;
        width:300px;
        background-size: 100% 100%;
        background-repeat:no-repeat;
        background-size: cover;
    }
}

/* scroll */
/* width */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #999;
    border-radius: 4px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(80, 173, 245);
    border-radius: 4px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(14, 130, 219);
  }

  h4 {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
  }