footer {
   display:flex;
   bottom: 0;
   left: 0;
   right: 0;
   height: 50px;
   width: 100%;
}

.LogoFooter {
   width: 20px;
   height: 20px;
}

.LogoFb {
  
   width: 40px;
   height: 40px;
}
     
.Text-F {
   font-size: 10px;
   color: #757575;
   margin: auto;
}